// log
import store from "../store";
import { allowlistAddresses }  from "../../allowlist";


const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {

      const blockchain = store.getState().blockchain
      let paused = await store.getState().blockchain.smartContract.methods.paused().call();
      let reserved = await store.getState().blockchain.smartContract.methods.NFTinStock().call();
      let totalCost = await store.getState().blockchain.smartContract.methods.totalCost().call()
      let viewCost = totalCost / 1000000000000000000;
      let saleTokenIds = await store.getState().blockchain.smartContract.methods.getSaleTokenIds().call()
      let buyerWalletAddress = await store.getState().blockchain.smartContract.methods.buyerWalletAddress().call()
      buyerWalletAddress = buyerWalletAddress.toLowerCase();
      //let saleTokenIds = [9,5,1];


      //let onlyAllowlisted = await store.getState().blockchain.smartContract.methods.onlyAllowlisted().call()
      //let maxMintAmountPerTransaction = await store.getState().blockchain.smartContract.methods.maxMintAmountPerTransaction().call();
      //let publicSaleMaxMintAmountPerAddress = await store.getState().blockchain.smartContract.methods.publicSaleMaxMintAmountPerAddress().call()
      //let allowlistUserAmount = await store.getState().blockchain.smartContract.methods.getAllowlistUserAmount(blockchain.account).call()
      //let allowlistType = await store.getState().blockchain.smartContract.methods.allowlistType().call()

      dispatch(
        fetchDataSuccess({
          paused,
          //reservedTokenId,
          reserved,
          totalCost,
          viewCost,
          saleTokenIds,
          buyerWalletAddress,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
